/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.popover_setting {
    --min-width: 400px !important;
    --min-height: 80% !important;
    --ion-text-color: #000!important;
    --padding: 30px 15px;
    padding: 30px 15px;
}

.cancel-button {
    color: red!important;
}

/* Circle Loader Start */
  
.loading-content {
    text-align: center;
  }
  
  // Define vars we'll be using
  $brand-success: #5cb85c;
  $loader-size: 100px;
  $check-height: $loader-size/2;
  $check-width: $check-height/2;
  $check-left: ($loader-size/6 + $loader-size/12);
  $check-thickness: 3px;
  $check-color: $brand-success;
  
  .disp {
    display: block!important;
  }
  .circle-loader {
  margin-left: auto;
  margin-right: auto; 
  margin-bottom: 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-left-color: $check-color;
  animation: loader-spin 1.2s infinite linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: $loader-size;
  height: $loader-size;
  }
  
  .load-complete {
  -webkit-animation: none;
  animation: none;
  border-color: $check-color;
  transition: border 1000ms ease-out;
  }
  
  .checkmark {
  display: none;
  
  &.draw:after {
    animation-duration: 1200ms;
    animation-timing-function: ease;
    animation-name: checkmark;
    transform: scaleX(-1) rotate(135deg);
  }
  
  &:after {
    opacity: 1;
    height: $check-height;
    width: $check-width;
    transform-origin: left top;
    border-right: $check-thickness solid $check-color;
    border-top: $check-thickness solid $check-color;
    content: '';
    left: $check-left;
    top: $check-height;
    position: absolute;
  }
  }
  
  @keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
  }
  
  @keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: $check-width;
    opacity: 1;
  }
  40% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
  100% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
  }
  
  /* Circle Load End*/